<template>
  <div>
    <vx-card class="directors-card">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="teacherData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :noDataText="noDataText"
      >
        <div slot="header" class="w-full">
          <div class="flex justify-between align-items-center mb-8">
            <div class="vx-breadcrumb md:block hidden">
              <ul class="flex flex-wrap items-center">
                <li class="inline-flex items-center">
                  <router-link :to="{ name: 'director-learning-centre-list'}">Manage</router-link>
                  <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
                </li>

                <li class="inline-flex items-center">
                  <a href="javascript:void(0)" class>Teachers</a>
                </li>
              </ul>
            </div>
            <br />
          </div>
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-8">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-xs="12" vs-sm="6" vs-lg="6">
              <vs-input
                class="w-full search-input"
                icon-pack="feather"
                icon="icon-search"
                v-model="searchQuery"
                placeholder="Search..."
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="left"
              vs-xs="12"
              vs-sm="6"
              vs-lg="6"
            >
              <vs-button color="primary" type="filled" @click="addNewTeacher">Add Teacher</vs-button>
            </vs-col>
          </vs-row>
        </div>
        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Room</vs-th>
          <vs-th>Role</vs-th>
          <vs-th>Username</vs-th>
          <vs-th>Reset Password</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].fullName">{{ data[indextr].fullName | capitalize }}</vs-td>
            <vs-td :data="data[indextr].room">{{ data[indextr].room | capitalize }}</vs-td>
            <vs-td :data="data[indextr].role">{{ data[indextr].role | capitalize }}</vs-td>

            <vs-td :data="data[indextr].username">{{ data[indextr].email }}</vs-td>

            <vs-td :data="data[indextr]._id">
              <a href="#" @click="resetPassword(data[indextr]._id)">Reset Password</a>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <a
                href="#"
                class="nav-link d-flex align-items-center active"
                @click.stop="viewHandler(data[indextr]._id)"
              >
                <feather-icon
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                />
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="pagination-wrapper">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        limit: 50,
        page: 1,
      },
      teacherData: [],
      serverResponded: false,
      searchQuery: "",
      awaitingSearch: false,
      addNewDataSidebar: false,
      sidebarData: {},
      noDataText: "Currently no teacher available",
    };
  },
  methods: {
    ...mapActions("center", ["getDirectorTeacherList", "resetUserPassword"]),

    resetPassword(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want reset password for this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.acceptPasswordResetAlert(id);
        }
      });
    },
    acceptPasswordResetAlert(id) {
      this.resetUserPassword(id)
        .then(() => {
          this.showChangePasswordSuccess();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    showChangePasswordSuccess() {
      this.$vs.notify({
        title: "Success",
        text: "Reset password mail sent to user.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "success",
      });
    },
    // ...mapActions("group", ["getAllCountries"]),
    async getTeacherData() {
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.directorId = this.director.id;
      this.$vs.loading();
      await this.getDirectorTeacherList(this.dataTableParams)
        .then((res) => {
          // let centers = this.$store.state.center.centers;
          this.teacherData = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.dataTableParams.page = res.data.data.pagination.page;
          this.serverResponded = true;
          if (this.teacherData.length == 0) {
            this.noDataText =
            "Currently no teacher available";
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          // console.error(err)
        });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getTeacherData();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getTeacherData();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getTeacherData();
    },
    viewHandler(id) {
      this.$router.push({ name: "director-teachers-view", params: { id: id } });
    },
    addNewTeacher(id) {
      this.$router.push({ name: "director-teachers-add" });
    },
    editDetailHandler(data, id) {
      this.sidebarData = data;
      this.dataId = id;
    },
    refreshData() {},
  },

  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    director() {
      return this.$store.state.AppActiveUser;
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getTeacherData();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getTeacherData();
      }
    },
    "dataTableParams.sort": function (newSort, oldSort) {
      if (newSort !== oldSort) {
        this.dataTableParams.page = 1;
        this.dataTableParams.sort = newSort;
        this.getTeacherData();
      }
    },
    searchQuery(val) {
      // console.log( val );
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getTeacherData();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  created() {
    let self = this;
    this.$vs.loading();

    setTimeout(() => {
      this.$vs.loading();
      self.getTeacherData();
    }, 5000);
  },
};
</script>

<style>
.vs-input--input.hasIcon:focus + .vs-input--placeholder {
  display: none !important;
}
</style>
